/* Custom CSS for the Appraisal form */

.custom-card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.custom-card-header {
    background-color: #f8f9fa;
    padding: 20px;
    border-bottom: 2px solid #ddd;
}


#appraisalForm .form-label {
    /* font-weight: bold; */
    margin-bottom: 5px !important;
    color: #222a42 !important;
}

.form-group {
    margin-bottom: 20px;
}

.input-field {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

.error {
    color: #ff0000;
    font-size: 12px;
    margin-top: 5px;
}

.button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.button-group .btn {
    margin-left: 10px;
}

.has-danger .input-field {
    border-color: red;
}

.has-success .input-field {
    border-color: green;
}
.card .card-header{
    padding: 15px !important;
}
.spinner-container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.appraisal-container textarea{
min-height: 130px !important;
}
.subtext{
    font-size: 10px;
}
.rating-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* .employee-info-table .rt-td, .employee-info-table .white-content .table > tbody > tr > td,
.ReactTable .rt-td, .white-content .table > tbody > tr > td,
.white-content .table > thead > tr > th, .white-content .table > tbody > tr > th, .white-content .table > tfoot > tr > th, .white-content .table > thead > tr > td, .white-content .table > tbody > tr > td, .white-content .table > tfoot > tr > td{
    padding: 12px 7px !important;
} */