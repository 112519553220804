.main-panel {
     border-top: none;
}

.project-table .rt-thead .rt-th:last-child,
.project-contact-table .rt-thead .rt-th:last-child,
.timesheet-report-table .rt-thead .rt-th:last-child {
     text-align: left;
}

.modal.show .custom-modal {
     transform: translate(0, 0%) !important;
}

.custom-modal .modal-content .modal-header {
     border-bottom: 1px solid #e9ecef;
}

.custom-modal {
     .col-form-label {
          padding-top: calc(1rem + 1px) ! important;
     }

     .modal-content .modal-footer {
          border-top: 1px solid #e9ecef;
          padding-top: 16px;
     }
}

.custom-modal .modal-content .modal-header .modal-title,
.designation-modal .modal-title h4 {
     margin-bottom: 10px;
}

.custom-modal-dialog .modal-content .modal-footer {
     border-top: 1px solid #e9ecef;
     padding-top: 10px;
}

.modal.show .custom-modal-dialog {
     transform: translate(0, 5%) !important;
}

.custom-modal-dialog .modal-content .modal-header {
     border-bottom: 1px solid #e9ecef;
}

.custom-modal-dialog .modal-content .modal-footer {
     border-top: 1px solid #e9ecef;
     padding-top: 16px;
}

.custom-form .has-success.has-label:after,
.custom-form .has-danger.has-label:after {
     content: none;
}


.raise-ticket {
     .form-group {
          width: 100%;
          margin-bottom: 0px;
     }
}

.modal-width {
     width: 700px !important;
}

.form-group {
     padding-top: 10px;
}

.text-header {
     font-size: 24px;
     font-weight: bold;
}

.timesheet-modal {
     h5 {
          padding-right: 8px;
     }

     .modal-footer {
          justify-content: flex-end;
     }

}



.align-center-accept {
     text-align: center;

     button:hover {
          border: 1px solid #5fcdde;
     }
}

.align-center-reject {
     text-align: center;

     button:hover {
          border: 1px solid #f77a60;
     }
}

.align-center {
     text-align: center;
}

.balance-leave {
     padding-left: 30px;
     font-size: 15px;
}

.leave-span {
     border: solid 1px #5e72e4;
     padding: 0px 15px;
     border-radius: 16px;
     color: white;
     background-color: #5e72e4;
}

.inner-table,
.custom-timesheet-table .custom-inner-table .inner-row tr:nth-of-type(odd) {

     background-color: transparent !important;
}

.inner-row .table td {
     border-top: 0px !important;
}


.contact-modal,
.timesheet-report-modal,
.project-modal {
     transform: translate(0, 0%) !important;

     .col-form-label {
          padding-top: calc(1rem + 1px) ! important;
     }

     .modal-content .modal-footer {
          border-top: 1px solid #e9ecef;
          padding-top: 16px;
     }

     .modal-content .modal-header {
          border-bottom: 1px solid #e9ecef;
     }

     .modal-title {
          margin-bottom: 0px !important;
     }
}

.timesheet-report-modal.modal-xl.timesheet-report-modal {
     transform: translate(0, 0%) !important;

     .modal-body {
          max-width: 100%;
     }

     .table-responsive {
          overflow: auto !important;
          // max-height: 450px !important;
     }

}

.timesheet-report-modal {
     max-width: 800px !important;
}

// form styles 

.form-group .form-control,
.input-group .form-control {
     padding: 0px 18px 0px 18px !important;
}

.form-control {
     height: 2.2rem !important;
     font-size: 0.80rem !important;
}

.input-group-text {
     height: 2.2rem !important;
}

.custom-btn {
     padding: 11px 33px !important;
     font-size: 0.80rem !important;
}

.sidebar .nav li>a,
.off-canvas-sidebar .nav li>a,
.sidebar .nav li>a+div .nav li>a,
.off-canvas-sidebar .nav li>a+div .nav li>a {
     padding: 5px 8px !important;
}

.ReactTable .rt-td,
.white-content .table>tbody>tr>td {
     padding: 0px 7px !important;
}

#employeeInfoTable td{
     padding: 12px 7px !important;
}
.download-excel-btn{
     float: right;
     margin-bottom: 15px;
}


@media screen and (max-width: 767px) and (min-width: 360px) {
     .timesheet-btn {
          float: left !important;
     }
}

// end of form styles

// sidebar styles 

.main-panel>.content {
     padding: 80px 20px 30px 245px !important;

}

@media (min-width: 992px) {
     .sidebar-mini .main-panel>.content {
          // padding-left: 130px;
          padding-left: 110px !important;
     }
}

.sidebar-mini .main-panel>.content {
     padding-left: 95px !important;
}


.sidebar,
.off-canvas-sidebar {
     margin-left: 7px !important;
}

@media (max-width: 1199.98px) and (min-width: 768px) {
     .main-panel .content {
          padding-left: 20px !important;
     }
}

@media (max-width: 1199.98px) and (min-width: 768px) {
     .white-content .sidebar {
          box-shadow: none;
     }

     .nav-open .sidebar {
          transform: translate3d(7px, 0, 0) !important;

     }

     .sidebar,
     .off-canvas-sidebar {
          margin-left: 0px !important;
     }

}

.sidebar .nav li.active>a[data-toggle="collapse"]:before,
.off-canvas-sidebar .nav li.active>a[data-toggle="collapse"]:before {
     content: none !important;
}

//end of sidebar styles

.custom-form label {
     font-size: 0.80rem !important;
}

.btn-sm {
     padding: 8px 15px !important;
}

.react-switch-handle {
     height: 26px !important;
     width: 26px !important;
     top: 1px !important;
}

.btn+.btn {
     margin-left: 5px;
}

// .custom-inner-table 
#timesheet-inner-table td {
     padding: 0.1rem !important;
}

textarea.form-control {
     height: auto !important;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next,
.ReactTable .-pagination .-center {
     flex: none
}

.ReactTable .-pagination input {
     max-width: 50px;
}

.ReactTable .-pagination .-btn {
     padding: 8px 15px !important;
}

.ReactTable .-pagination select:focus {
     color: #222a42 !important;
}

.btn-wd {
     min-width: auto !important;
     padding: 7px 15px !important;
}

.rdt {
     width: 100% !important;


}

.form-group {
     padding-top: 5px !important;
}

.custom-form,
.project-form {

     .input-group,
     .form-control {
          margin-bottom: 5px !important;
     }
}



.input-group .card-wizard .card-footer div,
.card-wizard .btn {
     float: right !important;
     margin-left: 2px;
     margin-right: 2px;
     font-size: 12px;
}

.input-group .form-control:first-child,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
     border-right: 1px solid #cad1d7 !important;
}

.leaveReport .react-select__control {
     height: 35px !important;
     max-height: 35px !important;
     min-height: 35px !important;
}

// .react-switch-bg{
//      //  height: 28px;
//      // width: 56px;
//     height: 23px !important;
//      width: 51px !important;
//  }

.client_error {
     color: red;
}

// .rows .per .page,
.white-content .ReactTable .-pagination select {
     display: none;
}

// button:focus,
.Table__pageButton--active:focus,
.Table__pageButton--active:active,
button:focus {
     outline: none !important;
}

// wizard 

// .custom-wizard{
//      padding: 15px 30px;
//      .card-wizard .card-body{
//           margin-top: 0px !important;
//           padding-top: 0px !important;
//           padding-bottom: 0px !important;

//      }
//      .tab-content {
//           padding-top: 0px !important;
//      }
//      .badge{
//           line-height: 17px !important;
//      }
//      .card-footer{
//           padding: 0px !important;
//      }
//      .card-wizard .card-footer div:first-child{
//           padding-right: 0px !important;
//      }
// }

//card 

.custom-card {
     border: 0.0625rem solid rgba(34, 42, 66, 0.05) !important;

     .card-header {
          display: flex;
          justify-content: space-between;
          padding: 8px 10px;
          border-bottom: 0.0625rem solid rgba(34, 42, 66, 0.05) !important;

     }

     .table-responsive {
          overflow: auto !important;
     }



     // .white-content .table > tbody > tr > td,
     .custom-billing-table>tbody>tr>td {
          border: 0 !important;
          padding: 0px 7px !important;
     }
}

// .custom-card,
.billing-block {
     max-width: 866px;
     margin: auto;

     .form-group {
          padding-top: 10px !important;
          // margin-bottom:10px !important;

          margin-bottom: 0px !important;
          margin-top: 0px !important;
          padding-top: 0px !important;

     }

     .card-footer {
          padding: 0px 15px 15px !important;
     }

     .card-body {
          margin-top: 0px !important;
          padding-bottom: 0px !important;
     }

     .table-responsive {
          padding-bottom: 0px !important;
     }



     .btn.btn-icon:not(.btn-footer) .tim-icons {
          font-size: 1.3em !important;
     }

     .form-control {
          // height: 1.8rem !important;
          // border-radius: 0.2285rem;
          height: 2.2rem !important;
          font-size: 0.80rem !important;
     }

     .has-success:after,
     .has-danger:after {
          content: none !important;
     }
}

.card-wizard .card-footer div:nth-child(2) {
     float: right !important;
}

.expenses-billing-block {
     max-width: 925px;
     margin: auto;

     .form-group {
          padding-top: 10px !important;
          margin-bottom: 10px !important;
     }

     .card-footer {
          padding: 0px 15px 15px !important;
     }

     .card-body {
          margin-top: 0px !important;
          padding-bottom: 0px !important;
     }

     .table-responsive {
          padding-bottom: 0px !important;
     }

     .btn.btn-icon:not(.btn-footer) .tim-icons {
          font-size: 1.3em !important;
     }

     .form-control {
          height: 1.8rem !important;
          border-radius: 0.2285rem;
     }

     .has-success:after,
     .has-danger:after {
          content: none !important;
     }
}

.custom-resource-icon {
     top: -1px !important;
     margin-right: 5px !important;
}

.resource-modal {
     .col-form-label {
          padding-top: calc(0.5rem + 1px) !important;
     }

}



// employee dashboard
.desktop-name {
     padding: 10px 20px;
     margin-top: 5px;
     font-size: 24px;
     font-weight: bold;
}

.desktopname-span {
     font-size: 12px;
     font-weight: bold;
}

.desktop-icon {
     display: inline-block;
     border-radius: 50%;
     box-shadow: 0px 0px 2px #888;
     padding: 0.5em 0.7em;
}

.icon-change {
     margin: 10px;
}

.styled-card {
     border: 1px solid #cfd1d0;
     box-shadow: 1px 1px #888888 !important;
     padding: 10px;
}

.birthday-card {
     height: 100% !important;
     max-height: 240px !important;
     overflow-y: auto !important;
     overflow-x: hidden;
}

.btn-success,
.btn-danger {
     padding: 11px 21px !important;
}

//mobile responsive styles 
@media only screen and (max-width: 600px) {

     .sidebar,
     .off-canvas-sidebar {
          margin-left: 0px !important;
     }

     .main-panel>.content {
          padding: 80px 15px 30px 15px !important;
     }

     .sidebar-mini .main-panel>.content {
          padding-left: 15px !important;
     }

     .white-content .sidebar {
          box-shadow: none !important;
     }
}


// no data found 

.project-contact-table {
     .rt-noData {
          transform: translate(-50%, 10%) !important;
     }
}

//show counts on dashboards
.dash-count {
     text-align: center;
     font-size: 1.25rem;
     padding-top: 10px;
}

.dash-header {
     text-align: center;
     font-weight: bold;
}

.count-card {
     @media (min-width: 768px) {

          //CSS
          height: 80px,

     }

     background-color: whitesmoke !important;



}

.char-card {
     // @media (min-width: 1025px)  {

     //      //CSS
     padding: 0px 5px;

     //    }
     background: linear-gradient(#aac0e3, white) !important;
     border: 1px solid #cfd1d0;
}

.card-color-1 {
     background-color: #9fc0f5 !important;
}

.card-color-2 {
     background-color: #b0e88e !important;
}

.count-padding-right {
     padding-right: 5px !important;
}

.count-padding-left {
     padding-left: 5px !important;
}

label {
     margin-bottom: 0.0rem !important;
}

.image-upload-button {
     padding: 5px 20px !important;
}

.fileinput .thumbnail {
     max-width: 200px !important;
     max-height: 140px !important;
     height: 100% !important;
}

.timesheet-start-date {
     min-width: 120px !important;
}

.timesheet-actions {
     width: 14% !important;
}

.inner-row .row {
     margin-left: -6px !important;
     margin-right: 0px !important;
}

@media (max-width:1023px) {
     .timesheet-actions {
          display: none !important;
     }

     .timesheet-date {
          width: 18% !important;
     }

     .timesheet-totalHours {
          width: 23% !important;
     }

     .timesheet-task {
          width: 22% !important;
     }

     .inner-row .row {
          margin-left: -6px !important;
          margin-right: -14px !important;
     }

     .btn-save {
          float: right;
          margin-right: 5px;
          width: 80px
     }

     .btn-submit {
          float: right;
          width: 90px;
     }

     .btn-copy {
          float: right;
          width: 146px;
     }

     .week-start-date {
          max-width: 200px !important;
     }

     .week-section {
          justify-content: flex-end;

          label {
               margin-right: 3px;
               font-size: 1.0625rem !important;
          }
     }

     .logged-hours-section {
          float: left !important;
     }

     .timesheet-actions-section {
          margin-top: 0px !important;
     }
}

@media (min-width:1024px) {
     .timesheet-date {
          width: 12% !important;
     }

     .timesheet-totalHours {
          width: 12% !important;
          padding-left: 0px !important;
     }

     .timesheet-project {
          width: 24% !important;
     }

     .timesheet-task {
          width: 43% !important;
     }

     // .timesheet-task{
     //      width: 25% !important;
     // }
     // .timesheet-project{
     //      width: 25% !important;
     // }
     .timesheet-actions {
          width: 15% !important;
     }

     .inner-row {
          .inner-project-name {
               // width:33px !important;
               border-top: 0px !important;
               width: 28% !important;

          }

          .inner-task-name {
               // width:33px !important;
               border-top: 0px !important;
               width: 48% !important;

          }
     }

}

.datePicker .rdtPicker th.rdtSwitch {
     border: none;
     color: #e14eca !important;
}

.datePicker .rdtPicker th.rdtPrev {
     border: none;
     // color: #e14eca !important;
     // color: #4c840e  !important;
     color: #2e419f !important;
}

.datePicker .rdtPicker th.rdtNext {
     border: none;
     // color: #e14eca !important;
     // color: #4c840e  !important;
     color: #2e419f !important;

}

.datePicker .rdtPicker th.dow {
     border: none;
     // color: #e14eca !important;
     // color: #4c840e  !important;
     color: #2e419f !important;
}

.datePicker .rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtMonth:hover,
.rdtPicker td.rdtYear:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker .rdtTimeToggle:hover:before {
     // background: #e14eca;
     // background: #4c840e  !important;
     color: #2e419f !important;
     cursor: pointer;
     color: white;
}

.custom-timesheet-table,
.custom-billing-table {

     // th{
     //      color:#f4f5f7 !important;
     // } 
     th,
     td {
          border-top: 0px !important;
     }

     .input-group,
     .form-control {
          border-radius: 5px !important;
     }
}

.custom-timesheet-table th,
.custom-billing-table th {
     color: rgba(255, 255, 255, 0.7) !important;
}

.custom-billing-table {
     th {
          padding-left: 10px !important;
     }

}

.custom-card-pagination {
     border: 1px solid #faf5f5 !important;
     box-shadow: 3px 3px #faf5f5 !important;
     border-radius: 10px !important;

}

.input-position {
     margin-top: 10px !important;
}

.card-wizard .card-header {
     padding: 0px 15px 0 !important;
     margin-top: -50px !important;
}

.card-wizard .card-body {
     margin-top: 0px !important;
}

.typography-line {
     padding-left: unset !important;
}

.contact-modal .modal-header,
.modal-header {
     padding: 20px !important;
     padding-bottom: 0px !important;

}

.modal-footer {
     padding: 10px 20px !important;
}

.custom-employee-form {

     .input-group,
     .form-control {
          margin-bottom: 5px !important;
     }

     .input-group,
     .form-control {
          border-radius: 5px !important;
     }
}

.margin-to-table-field {
     margin-top: 10px !important;
}

.error {
     color: #ec250d !important;
}

.btn-save-info {
     padding: 7px 27px !important;
}

// timehseetReport css 
.timesheet-report-table {
     .rt-noData {
          top: 100% !important;
     }

}


.btn-secondary.disabled,
.btn-secondary:disabled {
     color: #ffffff !important;
}

// enter timesheet css
#timesheet-inner-table {
     textarea {
          padding-top: 3px !important;
          //     padding-bottom: 6px !important;
          padding-left: 0.7rem !important;
          height: 2.2rem !important;

     }

     ::placeholder {
          font-size: 12px !important;
     }

     .totalHours {
          padding: 0.5rem !important;
     }
}

.full-page {
     .custom-login-content {
          padding-bottom: 40px !important;
          padding-top: 40px !important;
     }
}

.timesheet-panel,
.week-section {
     display: flex;
     flex-direction: row;
     align-items: center;
}

.week-section {
     label {
          font-size: 0.75rem;
     }
}

.timesheet-actions-section {
     display: block;
     float: right;
     margin-top: 15px;
}

.logged-hours-section {
     display: inline;
     float: right;
     margin-top: 10px;
     margin-right: 15px;
}

.btn-save {
     float: right;
     margin-right: 5px;
     width: 80px
}

.btn-submit {
     float: right;
     width: 90px;
}

.btn-copy {
     float: right;
     // width: 90px;
     width: 146px;
}

.expense-table,
.project-table {
     .rt-td {
          display: flex !important;
          margin: 0px auto !important;
          align-items: center !important;
          height: 28px !important;
     }

     .actions-center {
          .btn {
               padding: 0px !important;
               height: 16px !important;
          }

          .rt-td {
               height: 28px !important;
          }

          .badge {
               margin-bottom: 0px !important;
               padding: 5px !important;
          }
     }
}


.project-document-upload {
     .fileinput {
          margin-top: 70px !important;
     }

     .badge {
          margin: 0 !important;
     }
}

.custom-badge-section {
     margin-top: 10px !important;
     margin-left: -50px !important;
}

.upload-section {
     margin-left: 12px !important;
}

.display-btn {
     display: inline !important;
}

.hide-btn {
     display: none !important;
}

.quarterly-report-section,
.monthly-report-section,
.weekly-report-section {
     .card-body {
          margin-top: 5px !important;
          padding-top: 5px !important;
          padding-left: 0px !important;
          padding-bottom: 0px !important;
     }
}


@media (min-width:768px) {

     .quarterly-timesheet-report {
          max-width: 568px !important;
     }
}

@media (min-width:1024px) {

     .quarterly-timesheet-report {
          max-width: 747px !important;
          .rt-td:first-child,
          .rt-th:first-child // .rt-td>:nth-child(1), .rt-th>:nth-child(1)

               {
               margin: 0px !important;
          }


     }

     .timesheet-monthly-table,
     .timesheet-weekly-table {
          max-width: 564px !important;
     }

}

@media (min-width:1024px) and (max-width:1200px) {

     .quarterly-timesheet-report {
          max-width: 698px !important;
     }

     .timesheet-monthly-table {
          max-width: 529px !important;
     }

}

#custom-view-table {
     tr td:nth-child(odd) {
          font-weight: 550;
     }

     tr td:nth-child(even) {}
}

.claim-modal {
     .modal-body {
          padding-top: 0px !important;
     }
}

.project-details-label {
     padding-right: 5px;
     font-weight: 600;
}

.manager-timesheet-content {
     .btn-icon {
          margin-left: 15px !important;
          margin-right: 15px !important;
     }
}

.custom-icon {
     font-size: 30px !important;
     margin-top: -10px !important;
}

.custom-excel-icon {
     color: #344675 !important;
     font-size: 30px;
}

#tooltip209892,
#tooltip209893,
#tooltip209894,
#tooltip209897 {
     padding-left: 0px !important;
}

#tooltip209897,
#tooltip209896,
#tooltip209898 {
     margin-left: 15px !important;
     margin-right: 15px !important;
     padding-left: 0px !important;
}

.invoice-table {
     max-width: 250px !important;
     margin-bottom: 0px !important;
}

.invoice-btn {
     padding: 5px 15px !important;
     font-size: 0.75rem !important;
     line-height: 1.35 !important;
     color: #fff !important;
     min-width: 61px;
}

#custom-invoice-table,
#custom-monthly-invoice-table {
     td {
          padding: 4px 7px !important;
     }
}

.custom-action-right {
     width: 100% !important;
}

.notification-dropdown {
     overflow-y: scroll !important;
     max-height: 400px !important;
}

#timesheet-inner-table,
.custom-billing-table {
     select {
          &:invalid {
               color: gray;
          }

          [disabled] {
               color: gray;
          }

          option {
               color: black;
          }
     }
}

.project-name {
     font-size: 0.85rem;
}

.rejection-description {
     font-size: 0.85rem;
}

.rejection-note {
     margin-top: 5px;

     ul {
          padding-left: 18px !important;
     }

     h4 {
          margin-bottom: 8px !important;
     }
}

.timesheet-title {
     display: flex;
     justify-content: space-between;
}

.timesheet-status {
     padding-right: 6px !important;
}

.custom-sidebar-logo {
     // background-color: rgb(0, 228, 203);
     // position: fixed;
     // padding: 7px 56px 3px 19px;
}

.sidebar .nav {
     margin-top: 20px !important;
}

.off-canvas-sidebar .nav li>a+div .nav,
.sidebar .nav li>a+div .nav {

     margin-top: 5px !important;
}

.dasboard-image {
     height: 60px !important;
     width: 60px !important;
     object-fit: cover !important;
     border-radius: 50% !important;
}

.top-bar-image {
     width: 50px;
     height: 50px;
     // object-fit: cover !important;
     border-radius: 50% !important;
}

.sidebar-image {
     height: 40px !important;
     width: 40px !important;
     object-fit: cover !important;
     border-radius: 50% !important;
}

.sidebar .logo a.logo-mini {
     width: 40px !important;
}

.top-bar-photo {
     height: 50px !important;
     width: 50px !important;
}

.invoice-project-name {
     margin-left: -15px;
     font-size: 0.90rem;
     // color: red;
}

.invoice-error-description {
     // margin-left: 15px;
     font-size: 0.85rem;

}

.invoice-label {
     font-size: 0.90rem;
     color: red !important;
     margin-bottom: 8px !important;
}

.invoice-note,
.monthly-invoice-note {
     border: 0.5px solid red;
     max-width: 840px;
     padding: 20px !important;
     padding-left: 10px !important;
     padding-right: 10px !important;
     margin-top: 8px !important;
     margin-bottom: 8px !important;

     ul {
          padding-left: 15px !important;
     }

     max-height: 400px;
     overflow-y: auto;
}

.monthly-invoice-note {
     max-width: 795px !important;
}

#custom-invoice-table,
#custom-monthly-invoice-table {
     thead>tr>th {
          font-weight: 600 !important;
     }
}

.comment {
     text-overflow: ellipsis;
     white-space: nowrap;
     overflow: hidden;
}

.manager-monthly-timesheet-table {
     max-width: 796px;
}

.manager-quarterly-timesheet-report {
     max-width: 841px;
}

@media screen and (max-width: 991px) and (min-width: 360px) {
     .manager-monthly-timesheet-table {
          max-width: 100%;
     }

     .manager-quarterly-timesheet-report {
          max-width: 100%;
     }
}

.sidebar .nav li>a {
     font-size: 0.70rem !important;
}

.custom-total-hours {
     padding-right: 17px !important;
}

.description-column {
     display: flex;
     align-items: flex-start;
     flex-direction: row;
}

.contact-custom-modal-title,
.custom-modal-title {
     margin-bottom: 10px !important;
     font-size: 1.0625rem !important;
}

.contact-modal {
     .contact-custom-modal-title {
          margin-bottom: 20px !important;
     }
}

.custom-wizard {
     .disabled {
          display: flex !important;
     }

}

.btn-announcement {
     border: 1px solid grey;
     border-radius: 50%;
}

.sidebar {
     // background: linear-gradient(45deg, #98d05a 0%, #4c840e 100%) !important;
     // background: linear-gradient(45deg, #000B41 0%, #000B41 100%) !important;
     background: linear-gradient(45deg, #2e419f 0%, #2e419f 100%) !important;
}

.sidebar:before {
     // border-bottom-color:#4c840e !important;
     // border-bottom-color:#000B41 !important;
     border-bottom-color: #2e419f !important;
}

.project-title {
     font-size: 1rem;
}

.custom-project-name {
     font-weight: 600;
     color: rgba(34, 42, 66, 0.7) !important;
}

.claim-modal-table {

     .attachment-column {
          flex-wrap: wrap;
          height: auto !important;
          padding: 2px !important;
     }

     // .rt-tr {
     //      // align-items: center;
     // }

     .badge+.badge {
          margin-left: 2px !important;
     }
}


@media screen and (min-width: 769px) {
     .single-input-adjustment {
          margin-left: 10px !important;
     }

     .single-input-text {

          width: 242px !important;
     }
}

@media screen and (max-width: 768px) {
     .single-input-adjustment {
          margin-left: -10px !important;
     }

     .single-input-text {

          width: 290px !important;
     }
}

.leave-count {
     padding: 2px 10px;
     border: 0px solid white;
     // background: #4c840e;
     background: #2e419f;
     // background-image: linear-gradient(to bottom left, #4c840e, #98d05a, #4c840e);
     background-image: linear-gradient(to bottom left, #2e419f, #3e51ad, #2e419f);
     border-radius: 13px;
     color: white;
}

.header-right {
     text-align: right !important;
     padding-right: 24px !important;
}

.custom-actions-center {
     justify-content: center;
     width: 100%;
}

.custom-card .custom-billing-table>tbody>tr>.resources-loader-td {
     padding-top: 50px !important;
}

.card .card-header .card-title {
     font-weight: 500;
}

.btn-cancel {
     width: 80px;
     position: absolute;
     bottom: 15px;
     left: 15px;
}

.custom-wizard {
     .card-wizard .card-footer div:first-child {
          padding-right: 0px !important;
     }
}

#timesheet-inner-table {
     textarea:focus {
          height: 5rem !important;
     }
}

#appraisalForm{
     .textarea.form-control
     {
         height:unset !important;
     }
     
 }